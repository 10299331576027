import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core/";
import { getJWTInfo } from "./session";
import Alert from '@material-ui/lab/Alert';
import { updatePackage } from "./util";
import beep_url from "./assets/beep.mp3";

function SnackPack() {
//   const [info, setInfo] = useState({});
  const [state, setState] = useState();
  const [response, setResponse] = useState();
  const beep = new Audio(beep_url);
  
  useEffect(() => {
    // setInfo(getJWTInfo());
    // console.log(getJWTInfo());
    if(getJWTInfo().account_type !== 'admin'){
        window.location = "/home"
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      marginTop: 60,
      marginBottom: 60,
      paddingTop: 60,
      background:"#f9cae1"
      // flexGrow:1
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  }));

  const onInputChange = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  //update package
  const upPack = async (e) => {
    e.preventDefault();
    // if (state.houseNumber.substr(0, 3).toUpperCase() == "CBX") {
      if (state?.houseNumber) {
        let housenum = state.houseNumber;
        try {
          let res = await updatePackage(housenum);
          console.log((res), "ress");
          if (res) {
            setResponse({...res.data?.message, type:"success"});
            beep.play();       
          } else {
            setResponse({name:"*** NOT FOUND ***", value: housenum, type: "error"});
          }
        } catch (error) {
            setResponse({name:"*** NOT FOUND ***", value: housenum, type: "error"});
        }
        setState({houseNumber:''});
      }
  };

  const houseKeyUp = (e) => {
    // console.log(e.key)
    // console.log(e.charCode)
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
        <Typography component="p">
            Scan packages and make them ready.
          </Typography>
          <form
            style={{ marginTop: 10 }}
            noValidate
            autoComplete="off"
            onSubmit={upPack}
          >
            <TextField
              style={{ width: "50%" }}
              name="houseNumber"
              label="House Number"
              onKeyUp={houseKeyUp}
              onChange={onInputChange}
              variant="outlined"
              value={state?.houseNumber?state.houseNumber:''}
              autoFocus
            />
          </form>
        </Grid>
        <Grid item xs={12} style={{paddingTop:20}}>
          {response?.name?<Alert severity={response.type} style={{fontSize:"1.0em"}}>{response.name+" - "+response.value}</Alert>:''}         
        </Grid>
      </Grid>
    </div>
  );
}
export default SnackPack;
