import { getSessionToken } from "./session";

const axios = require("axios").default;
// axios.defaults.baseURL = 'http://172.16.3.136:5000';
axios.defaults.timeout = 6000;


export const config = {
  domain: "",
  domain_auth: "",
}
if(process.env.NODE_ENV === 'development'){
  config.domain = "http://localhost:5652";
  config.domain_auth = "http://localhost:5651";

  // config.domain = "https://api.shippcartja.com";
  // config.domain_auth = "https://auth.shippcartja.com";
    
} else {
  config.domain = "https://api.shippcartja.com";
  config.domain_auth = "https://auth.shippcartja.com";

  // config.domain = "http://localhost:5652";
  // config.domain_auth = "http://localhost:5651";
}
axios.defaults.baseURL = config.domain;


export async function postData(url = "", data = {}, headr = {}) {
  // Default options are marked with *
  const response = await fetch(config.domain+url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headr,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response) {
    return await response.json(); // parses JSON response into native JavaScript objects
  } else {
    return {};
  }
}
export async function postData_(url = "", data = {}, headr = {}) {
  // Default options are marked with *
  const response = await fetch(config.domain_auth+url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headr,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response) {
    return await response.json(); // parses JSON response into native JavaScript objects
  } else {
    return {};
  }
}

export async function getData(url = "", data = {}, headr = {}) {
  // Default options are marked with *
  const response = await fetch(config.domain+url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: headr,
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  if (response) {
    return await response.json(); // parses JSON response into native JavaScript objects
  } else {
    return {};
  }
}

export async function updatePackage(houseNumber) {
  try {
    const response = await axios.put(
      "/u1/shipment-bearer-ready",
      {
        houseNumber: houseNumber,
      },
      {
        headers: { Authorization: `Bearer ${getSessionToken()}` },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    //   return error;
    // console.log(error, "upFunc");
    throw new Error();
  }
}

export async function updatePackageCharge(value) {
  try {
    const response = await axios.put("u1/shipment-charge/",
      {
        duty_charge: value.duty_charge,
        freight_charge: value.freight_charge,
        handling_charge: value.handling_charge,
        house_number: value.houseNumber,
        account_id: value.account_id,
        weight: value.weight,
        new_weight_charge: value.new_weight_charge
      },
      {
        headers: { Authorization: `Bearer ${getSessionToken()}` },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    return error;
    // console.log(error, "upFunc");
    // throw new Error();
  }
}

export async function checkPackage(houseNumber) {
  try {
    const response = await axios.put(
      "/u1/shipment-info",
      {
        houseNumber: houseNumber,
      },
      {
        headers: { Authorization: `Bearer ${getSessionToken()}` },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    //   return error;
    // console.log(error, "upFunc");
    throw new Error();
  }
}

export async function getBuild() {
  //     var fs = require("fs");
  //   console.log("Incrementing build number...");
  //   fs.readFile("src/metadata.json", function (err, content) {
  //     if (err) throw err;
  //     var metadata = JSON.parse(content);
  //     return metadata.build;
  //   });
try {
    // const b = await axios.get("src/metadata.json");
    // console.log(b)
    // return b;
    const b = await fetch('metadata.json').then(response => {
        return response.json();
      }).then(data => {
        // Work with JSON data here
        return data;
      }).catch(err => {
          console.log(err);
          return err;
        // Do something for an error here
      });

      return b;

} catch (error) {
    console.log(error)
}

}
