import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core/";
import { getJWTInfo } from "./session";
import Alert from "@material-ui/lab/Alert";
import { checkPackage, updatePackageCharge } from "./util";
import beep_url from "./assets/beep.mp3";
import Snackbar from '@material-ui/core/Snackbar';
import {getData} from './util'

function ScanIdentify() {
  //   const [info, setInfo] = useState({});
  const [state, setState] = useState();
  const [housenumber, setHouseNumber] = useState();
  const [response, setResponse] = useState();
  const [chargeResponse, setChargeResponse] = useState();
  const beep = new Audio(beep_url);
  const [show, setShow] = useState(false);
  const [alertMsg, setAlertMessage] = useState("");
  
  const [rates, setRates] = useState({});

  const fetchRates = async () => {
    const data = await getData("/rates/300", {}, {
        'Content-Type': 'application/json'});
    setRates(data);
};


  useEffect(() => {
    // setInfo(getJWTInfo());
    // console.log(getJWTInfo());
    if (getJWTInfo().account_type !== "admin") {
      window.location = "/home";
    }

  fetchRates()

  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      marginTop: 60,
      marginBottom: 60,
      paddingTop: 60,
      // flexGrow:1
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    textField: {
      marginTop: 10,
      // marginRight: theme.spacing(1),
      width: "25ch",
    },
    submit: {
      width:"80%",
      margin: theme.spacing(3, 0, 2),
  }
  }));

  const calcWeightCharge = (w) => {
    let p = rates[Math.ceil(w)-1]?.price;
    console.log(p)
    if(p) return p;
  }
  
  const onInputChange = (e) => {
  
    if(e.target.name === 'weight' && e.target.value === '0'){
      setState({...state, [e.target.name]: 1 });
      return;
    }

    
    if(e.target.name === 'weight' && e.target.value === '0'){
      setState({...state, [e.target.name]: 1 });
      return;
    }  
    if(e.target.name === 'weight'){
    
      setState({...state, new_weight_charge: calcWeightCharge(e.target.value), [e.target.name]: e.target.value });
      // console.log(state, "WEIGHT")
      // console.log(calcWeightCharge(e.target.value))
      return;
    }

    setState({...state, [e.target.name]: e.target.value });
  };
  
  const onHouseChange = (e) => {
    setHouseNumber(e.target.value);
  };


  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setShow(false);
};


  //update package to seen, and get duty charges
  const upPack = async (e) => {
    e.preventDefault();
    // if (state.houseNumber.substr(0, 3).toUpperCase() == "CBX") {
    if (housenumber) {
      let housenum = housenumber;
      try {
        let res = await checkPackage(housenum);
        // console.log(res, "ress");
        if (res) {
          setResponse({ ...res.data?.message, type: "success" });

          //set charges
          // console.log(res.data.charges, "charges");
          setState({
            houseNumber: housenum,
            account_id: res.data.account.account_id,
            duty_charge: res.data.charges.duty_charge,
            freight_charge: res.data.charges.freight_charge,
            handling_charge: res.data.charges.handling_charge,
            weight: res.data.charges.weight,
            weight_charge: res.data.charges.weight_charge,
            new_weight_charge: res.data.charges.weight_charge,
            total: new Intl.NumberFormat('en-US',
            { style: 'currency', currency: 'USD' }
          ).format(res.data.charges.total) 
          });
         setHouseNumber('');
          beep.play();
        } else {
          setResponse({
            name: "*** NOT FOUND ***",
            value: housenum,
            type: "error",
          });
          setHouseNumber('');
          setState({});
        }
      } catch (error) {
        setResponse({
          name: "*** NOT FOUND ***",
          value: housenum,
          type: "error",
        });
        setHouseNumber('');
        setState({});
      }
      //clear house number for a new scan
      // setState({houseNumber:''});
    }
  };

  //Update charges
  const upPackCharge = async (e) => {
    e.preventDefault();
    // if (state.houseNumber.substr(0, 3).toUpperCase() == "CBX") {

    if(window.confirm("Are you sure you want to make this update?")){
      console.log(state)
    } else {
      return;
    }
    if (state?.houseNumber && state?.duty_charge && state?.freight_charge && state?.handling_charge) {
      // let housenum = state.houseNumber;
      try {
        let res = await updatePackageCharge(state);
        // console.log(res, "charged");
          setAlertMessage(res.data.message.message);
          setShow(true);
        
      } catch (error) {
        // console.log(error, "error")
        setAlertMessage("*** Update Failed ****");
          setShow(true);
        // setChargeResponse({
        //   name: "*** NOT FOUND ***",
        //   value: housenum,
        //   type: "error",
        // });
        // setAlertMessage(error.message);
        //   setShow(true);
      }
      // setState({ houseNumber: "" });
    }
  };

  const houseKeyUp = (e) => {
    // console.log(e.key)
    // console.log(e.charCode)
  };



 
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography component="p">Get package and member details.</Typography>
          <form
            style={{ marginTop: 10 }}
            noValidate
            autoComplete="off"
            onSubmit={upPack}
          >
            <TextField
              style={{ width: "50%" }}
              name="houseNumber"
              label="House Number"
              onKeyUp={houseKeyUp}
              onChange={onHouseChange}
              variant="outlined"
              value={housenumber}
              autoFocus
            />
          </form>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          {response?.name ? (
            <Alert severity={response.type} style={{ fontSize: "1.2em" }}>
              {response.name + " - " + response.value}
            </Alert>
          ) : (
            ""
          )}
          {response?.name ? (
            <Grid item xs={12}>
             {state?.total?( <Alert severity={"info"} style={{ fontSize: "1.2em" }}>
                Current total is <b>{state.total}</b>
              </Alert>):''}
              <form
                style={{ marginTop: 10, marginBottom:60 }}
                noValidate
                autoComplete="off"
                onSubmit={upPackCharge}
              >

<div style={{backgroundColor:'rgb(253 236 234)'}}>
               
                  <TextField
                    // style={{ width: "50%" }}
                    style={{ width: "50%", marginTop: 20 }}
                    name="account_id"
                    label="Account ID"
                    onChange={onInputChange}
                    variant="outlined"
                    value={state?.account_id ? state.account_id : ""}
                  />
                </div>

                <div>
                  {" "}
                  <TextField
                    // style={{ width: "50%" }}
                    style={{ width: "50%", marginTop: 20 }}
                    name="duty_charge"
                    label="Customs Charge/Duty"
                    onChange={onInputChange}
                    variant="outlined"
                    value={state?.duty_charge ? state.duty_charge : ""}
                  />
                </div>
                <div>
                  <TextField
                    style={{ width: "50%", marginTop: 20 }}
                    name="freight_charge"
                    label="Delivery/Freight Charge"
                    onChange={onInputChange}
                    variant="outlined"
                    value={state?.freight_charge ? state.freight_charge : ""}
                  />
                </div>

                <div>
                  <TextField
                    style={{ width: "50%", marginTop: 20 }}
                    name="handling_charge"
                    label="Handling Charge"
                    onChange={onInputChange}
                    variant="outlined"
                    value={state?.handling_charge ? state.handling_charge : ""}
                  />
                </div>

                <div>
                  <TextField
                    style={{ width: "50%", marginTop: 20 }}
                    type="number"
                    name="weight"
                    label="Weight"
                    onChange={onInputChange}
                    variant="outlined"
                    value={state?.weight ? state.weight : ""}
                  />

                  <div><small>Fetched Weight Charge: {state?.weight_charge ? state.weight_charge : ""}</small></div>      
                  <div><small>Revised Weight Charge: {  (state?.weight && state?.weight !== 0 && state?.weight !== undefined) ? calcWeightCharge(state?.weight): ""}</small></div>
                </div>
                
                <div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    // disabled={!validateForm()}
                  > Save Changes
                  </Button>
                </div>
              </form>
            </Grid>
          ) : (
            ""
          )}
        </Grid> 
      </Grid>
     <div>
     <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={show}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                message={alertMsg}
            />
     </div>
    </div>
  );
}
export default ScanIdentify;
