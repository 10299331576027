import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import {getSessionToken } from './session';
import { postData } from './util';

export default function Deliver(props) {
   
    const userID = props.userID;
    let userOrders = props.userOrders;
    if(typeof userOrders !== 'undefined') {
        userOrders = userOrders.toString();
        if(userOrders.charAt(0) === ",") {
            userOrders = userOrders.substring(1);
        }      
    }
    
    // const uid = props.uid.toLowerCase().replace("cbx", "");
    const [pin, setMemberPin] = useState("");
    const [showSnack, setShowSnack] = useState(false);
    const [snackMsg, setSnackMsg] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    // console.log("userID", userID);
    // console.log("userOrders", userOrders);
   
    useEffect(() => {
      // const coords = (pos) =>{
      //   setLongitude(pos.coords.longitude);
      //   setLatitude(pos.coords.latitude);
      // }
      // navigator.geolocation.getCurrentPosition(coords);
  });

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnack(false);
    };

    const deliverShipment = async() => {
        // setMemberPin(pin.toLowerCase().replace("cbx", ""));
        const data = await postData("/u1/shipment-deliver", {pin:pin, uid:userID, order_id:userOrders, latitude:latitude, longitude: longitude}, {
            'Content-Type': 'application/json', Authorization: "Bearer " + getSessionToken()
          });
          const res = await data;
          if(res.error === false) {
            setSnackMsg(res.message);
            setShowSnack(true); 
            setMemberPin("");
            props.setTotalValue("$0.00");
            // console.log("$0");
            props.listClose();
            props.fecthCallback();
          } else {
            setSnackMsg(res.message);
            setShowSnack(true); 
            setMemberPin("");
            
          }
          
    }
    // function handleSubmit(event) {
    //     event.preventDefault();
    //     console.log(props.orders);
    //     // deliverShipment();

    // }
 
    const updateNumber = (e) => {
        const val = e.target.value;
        // If the current value passes the validity test then apply that to state
        if (e.target.validity.valid) setMemberPin(e.target.value);
        // If the current val is just the negation sign, or it's been provided an empty string,
        // then apply that value to state - we still have to validate this input before processing
        // it to some other component or data structure, but it frees up our input the way a user
        // would expect to interact with this component
        else if (val === '' || val === '-') setMemberPin(val);
      }

  return (
    <div>
      <Dialog open={props.show} onClose={props.listClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your total is: {props.total}
            </DialogContentText>
            <DialogContentText>
           Enter your PIN to confirm delivery.
          </DialogContentText>
          
          
          <TextField
            autoFocus
            margin="dense"
            id="pin"
            label="PIN"
            type="text"
            length="4"
            autoComplete="off"
            onChange={updateNumber}
            pattern="^-?[0-9]\d*\.?\d*$"
            inputProps={{
                maxLength: 4,
              }}
            value={pin}
            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.listClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deliverShipment} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showSnack}
                autoHideDuration={4000}
                onClose={handleCloseSnackBar}
                message={snackMsg}
            />
    </div>
  );
}
