import { postData, postData_ } from "./util.js";
var axios = require("axios");

  export const config = {
    domain: "",
    domain_auth: "",
  }
  if(process.env.NODE_ENV === 'development'){
    config.domain = "http://localhost:5652";
    config.domain_auth = "http://localhost:5651";
      
  } else {
    config.domain = "https://api.shippcartja.com";
    config.domain_auth = "https://auth.shippcartja.com";

    // config.domain = "http://localhost:5652";
    // config.domain_auth = "http://localhost:5651";
  }
  
  const axios_auth = axios.create({
    baseURL: config.domain_auth,
    timeout: 9000,
    // headers: {'X-Custom-Header': 'foobar'}
  });



export const getToken = () => {
  try {
    if (localStorage.getItem("token")) {
      return localStorage.getItem("token");
    } else {
      localStorage.removeItem("token");
      return "";
    }
  } catch (error) {}
};
export const authenticate = (email, password) => {
  return new Promise((resolve, reject) => {
    postData_("/login",
      { email: email, password: password, auth:'sc' },
      {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    ).then((data) => {
      if (!data.error) {
        
         resolve(data);     
       
        // setAuth(true);
        //history.push("/deliveries");
      } else {
        reject(data);
      }
    });
  });
};
export const logout = () => {
  localStorage.removeItem("token");
  if (localStorage.getItem("token")) {
    return false; //not logged out
  } else {
    return true; //logged out
  }
};

export const isAuthenticated = () => {
  console.log(getToken());
  return new Promise((resolve, reject) => {
    postData(
      "https://www.malidaddy.net/api/user/info",
      {},
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      }
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.email) {
          resolve(true);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        reject(false);
      });
  });
};



export async function doLogin(data) {
    try {
      const response = await axios_auth.post("login", data);
      // console.log(response);
      return response;
    } catch (error) {
      // return error.message;
      throw new Error(error);
    }
  }