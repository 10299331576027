import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import { Phone, SupervisedUserCircle} from '@material-ui/icons';
import { Grid, Paper, Typography } from "@material-ui/core";
// import {logOut} from './session';
import logo_sc from "./assets/shippcart.jpg";
import InstallPWA from "./InstallPWA";
import Chip from "@material-ui/core/Chip";
import { getSessionToken } from "./session";
import {getBuild, config} from './util';
// import { text } from './metadata.json';





function Home() {
  // var moment = require('moment');
  var moment = require("moment-timezone");
  const [build, setBuild] = useState('');
  let fr = moment()
    .subtract(1, "days")
    .tz("America/Jamaica")
    .format("YYYY-MM-DD");
  let to = moment().tz("America/Jamaica").format("YYYY-MM-DD");
  // let to2 = moment().add(1, "days").tz("America/Jamaica").format("YYYY-MM-DD");
  // moment().tz("America/Jamaica").format();
  const [yesterday, setYesterday] = useState({
    total: 0,
    packages: 0,
    delivered_date: "Yesterday",
  });
  const [today, setToday] = useState({
    total: 0,
    packages: 0,
    delivered_date: "Today",
  });
  const [locationMessage, setLocationMessage] = useState("");

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      marginTop: 60,
      marginBottom: 60,
      // flexGrow:1
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  }));

  const axios = require("axios");
  axios.defaults.headers.common = {
    Authorization: `Bearer ${getSessionToken()}`,
  };

  //geolocation request
  // if (!navigator.geolocation) {
  //   console.log("Geolocation is not supported by your browser");
  // } else {
  //   // status.textContent = 'Locating…';
  //   navigator.geolocation.getCurrentPosition(
  //     (success) => {
  //       // console.log(success.message,"success***");
  //       setLocationMessage("");
  //     },
  //     (err) => {
  //       console.log(err.message, "error***");
  //       setLocationMessage(
  //         err.message +
  //           ". Go to Privacy & Security - Location, and allow App"
  //       );
  //     }
  //   );
  // }

  const buildVersion = async() =>{
  //  const b = await getBuild()
   getBuild().then((res)=>{
     console.log(res);
    setBuild("Build: 1.0."+res.build);
}).catch((e) => {
  console.log(e);
})
  //  setBuild(JSON.parse(b)?.build);
  }

 
  useEffect(() => {

    // buildVersion();
    //Yesterday
    axios({
      method: "get",
      url: config.domain + "/u1/reportDay",
      params: { fr: fr, to: fr },
    })
      .then((res) => {
        // console.log(res.data.length,"yesterday"+fr+' '+to)
        if (res.data.length > 0) {
          setYesterday({
            total: res.data[0].total,
            packages: res.data[0].packages,
            delivered_date: "Yesterday",
            // delivered_date: res.data[0].delivered_date.substring(0,10),
          });
        }
      })
      .catch();
    //Today
    axios({
      method: "get",
      url: config.domain + "/u1/reportDay",
      params: { fr: to, to: to },
    })
      .then((res) => {
        console.log(res.data.length, "today");
        if (res.data.length > 0) {
          setToday({
            total: res.data[0].total,
            packages: res.data[0].packages,
            delivered_date: "Today",
          });
        }
      })
      .catch();
  }, [axios,fr,to]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ height: "100px", marginTop: 30 }}
          src={logo_sc}
        />
      </div>
      <small style={{ color: "red" }}>{locationMessage}</small>
      {/* <h2 style={{ marginTop: 20 }}>Have a great day, and be safe.</h2> */}
      <div><small style={{color:'#aaa',fontSize:'8pt'}}>{build}</small></div>
      <div>
        <InstallPWA />
      </div>
      <div>
        <Grid container style={{ marginTop: 30 }}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <small>{yesterday.delivered_date}</small>
              <Typography variant="h5" component="h2">
                {yesterday.total}
              </Typography>
              <Chip
                size="small"
                variant="outlined"
                label={yesterday.packages + " packages"}
                color="primary"
              ></Chip>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              className={classes.paper}
              style={{ background: "#524488", color: "#fff" }}
            >
              <small>{today.delivered_date}</small>
              <Typography variant="h5" component="h2">
                {today.total}
              </Typography>
              <Chip
                size="small"
                label={today.packages + " packages"}
                color="default"
              ></Chip>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Home;