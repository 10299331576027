import React from 'react';
import Nav from './Nav';
import Header from './Header';
import ShipmentList from './ShipmentList';
import ScanPack from './ScanPack';
import Home from './Home';
// import Verify from './Verify';
import Login from './Login';
import { ShipmentProvider } from './context/ShipmentContext'
import { BrowserRouter as Router, Switch} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// import AdminRoute from './AdminRoute';
import PublicRoute from './PublicRoute';
import './App.css';
import ScanIdentify from './ScanIdentify';
import DeliveryList from './DeliveryList';


function App() {
  return (
    
    <ShipmentProvider>
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <PrivateRoute exact path="/list" component={ShipmentList} />
            <PrivateRoute exact path="/deliveries" component={DeliveryList} />
            <PrivateRoute component={Home} path="/home" exact />
            <PrivateRoute component={Home} path="/" exact />
            <PrivateRoute component={ScanPack} path="/scan" exact />            
            <PrivateRoute component={ScanIdentify} path="/identify" exact />            
            {/* <PublicRoute restricted={false} component={Home} path="/home" exact />
            <PublicRoute restricted={false} component={Home} path="/" exact /> */}
            <PublicRoute restricted={true} component={Login} path="/login" exact />
            
          </Switch>
          <Nav />
        </Router>
      </div>
      
    </ShipmentProvider>
  );
}

export default App;

