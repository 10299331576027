import React, { forwardRef, useState, useContext, useEffect } from "react";
import MaterialTable, { MTableToolbar } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import SelectAll from '@material-ui/icons/SelectAll';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { DoneOutline, LocationOn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import Shipment from './Shipment';
import Deliver from './Deliver';
import { ShipmentContext } from './context/ShipmentContext';
// import { Typography } from "@material-ui/core";
import { getSessionToken, inSession } from './session';
import { getData } from './util';
import currency from 'currency.js'
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ShipmentList() {
    // const isAuthenticated = useAuth();
    const [shipments, setShipments] = useContext(ShipmentContext);
    const [total, setTotal] = useState("$0.00");
    const [totalDisplay, setTotalDisplay] = useState("$0.00");
    const [doAll, setDoAll] = useState(true);
    const [showSnack, setShowSnack] = useState(false);
   
    const fetchShipments = async () => {
        const data = await getData("/u2/shipments_/ready", {}, {
            'Content-Type': 'application/json', Authorization: "Bearer " + getSessionToken()
        });
        setShipments(data.data);
    };
    useEffect(() => {
        console.log("effect")
        // const fetchS = async () => {
        //     const data = await getData("https://www.malidaddy.net/api/admin/cbox/shipments-delivery/60/none?q"+Date.now(), {}, {
        //         'Content-Type': 'application/json', Authorization: "Bearer " + getSessionToken()
        //     });
        //     setShipments(data.data);
        // };
        if (inSession()) {
            console.log("fetch shipments in effect")
            fetchShipments();
        }
    }, [setShipments]);


    //Refresh shipments
    const tableRef = React.createRef();
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
        DoneOutline: forwardRef((props, ref) => <DoneOutline style={{ padding: 3, color: 'white', backgroundColor: 'green' }}  {...props} ref={ref} />)
    };
    const [shipmentTable] = React.useState({
    // const [shipmentTable, setShipmentTableData] = React.useState({
        columns: [
            {
                title: 'Name',
                field: 'fname',
                    // render: rowData => <div><div>{rowData.fname}</div><div style={{
                    //     fontSize: 12,
                    //     textAlign: 'left',
                    //     color: '#999',

                    // }}>{rowData.account_id}</div></div>
            },  {
                title: 'Surname',
                field: 'lname',
            },
            {
                title: 'Address',
                field: 'address',
                render: rowData => <div style={{
                    textAlign: 'left',
                    color: '#0a046c',
                }}><LocationOn /> {rowData.address}</div>
            },
            {
                title: 'Order#',
                field: 'id',
            },
            {
                title: 'Account',
                field: 'account_id',
            },
            {
                title: 'House#',
                field: 'house_number',
            }
        ],
        data: [],
    });
    shipmentTable.data = shipments;

    const useStyles = makeStyles({
        root: {
            marginTop: 60,
            marginBottom: 60,
            paddingBottom:60,
            fontSize: 12
        }
    });
    const classes = useStyles();

    const [allCheck, setAllCheck] = useState(false);
    // const allBtnClick = (e) => {
    //     //console.log(tableRef.current);
    //     tableRef.current.state.renderData.map(ren => {
    //         ren.tableData.checked = !allCheck;
    //         setAllCheck(!allCheck);
    //     })
    // }
    const [openD, setOpenD] = React.useState(false);
    // const [mypin, setMyPin] = React.useState(false);
    const [userID, setUserID] = React.useState('');
    const [userOrders, setUserOrders] = React.useState('');

    const handleClickOpen = (uid, order, total) => {
            localStorage.setItem("orders", order);
            setUserID(uid);
            setUserOrders(order);
            setOpenD(true); 
            setTotalDisplay(total);
        // console.log(mypin);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnack(false);
    };

    const handleClose = () => {
        setOpenD(false);
    };

    const handleDelivery = () => {
        setOpenD(false);
    };


    return (
        <div className={classes.root}>
            {/* <h3 style={{position:'fixed', zIndex:70, marginTop:0, textAlign:'center', fontSize:18, fontWeight:'bold', color:'white', backgroundColor:'#F50457'}}>{total}</h3> */}
            <Deliver userOrders={userOrders} userID={userID} show={openD} setTotalValue={setTotal} total={totalDisplay} fecthCallback={fetchShipments} listClose={handleClose} />
            <MaterialTable
                tableRef={tableRef}
                localization={{
                    body:{
                        // emptyDataSourceMessage: <CircularProgress color="secondary" />,
                        emptyDataSourceMessage: "No Packages",
                    },
                    // pagination: {
                    //     labelDisplayedRows: '{from}-{to} of {count}'
                    // },
                    // toolbar: {
                    //     nRowsSelected: total+' {0} row(s) selected'
                    // },
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <span style={{ fontWeight: 'bold', color: 'white', backgroundColor: '#F50457', fontSize: 18, padding: "3px", textAlign: "left" }}>
                                {total}
                            </span>
                        </div>
                    )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                icons={tableIcons}
                options={{
                    toolbar: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
                    selection: true,
                    showSelectAllCheckbox: false,
                    selectionProps: rowData => ({
                        // checked: {in_array(rowData.id, tableRef.current.state.renderData[0].id)},
                        // checked: tableRef.current.state.originalData.includes(tableRef.current.state.renderData),
                        // color: 'primary'
                    })
                }}

                title=""
                columns={shipmentTable.columns}
                data={shipmentTable.data}
                onSelectionChange={(rows) => {
                    // console.log('You selected ' + rows.length + ' rows', rows);
                    let sum = 0;
                    let total_charge;
                    let prev_id = '';
                    let order_list = "";
                    setDoAll(true);
                    rows.forEach(row => {
                        console.log(sum,"sum")
                        // sum = 90;
                        sum += currency(row.total_charge).value;
                        // sum += (row.total_charge).value;
                        if((row.account_id !== prev_id) && (prev_id !== "")){
                            setDoAll(false);
                            console.log("DOALL", doAll);
                        }
                        order_list = order_list+","+row.id;
                        prev_id = row.account_id;
                    })
                    setUserID(prev_id);
                    setUserOrders(order_list);
                    total_charge = 0;
                    total_charge = value => currency(value, { symbol: "$", precision: 2 });
                    setTotal(total_charge(sum).format(true));
                    setTotalDisplay(total_charge(sum).format(true));

                    // console.log("--",order_list);
                }
                }
                onChangePage={(page) => {
                    console.log(page)
                }
                }
                actions={[
                    {
                        tooltip: 'Deliver ALL Packages',
                        icon: tableIcons.DoneOutline,
                        onClick: (evt, data) => {(doAll)?setOpenD(true):setShowSnack(true)}
                        // onClick: (evt, data) => alert('Confirm delivery for ' + data.length + ' packages')
                    },
                    {
                        icon: tableIcons.SelectAll,
                        tooltip: 'Select all',
                        position: 'toolbar',
                        onClick: () => {
                            setAllCheck(!allCheck);
                            setDoAll(true);
                            let sum = 0;
                            let order_list = "";
                            let total_charge;
                            let prev_id = '';
                            tableRef.current.state.renderData.forEach(ren => {
                                sum += currency(ren.total_charge).value;
                                ren.tableData.checked = true;
                                //store orders
                                order_list = order_list+","+ren.id;
                                if ((ren.account_id !== prev_id) && (prev_id !== "")) {
                                    setDoAll(false);
                                }                                
                                prev_id = ren.account_id;
                            })
                            setUserID(prev_id);
                            setUserOrders(order_list);
                        //   console.log("--",order_list);
                            total_charge = value => currency(value, { symbol: "$", precision: 2 });
                            setTotal(total_charge(sum).format(true));
                            setTotalDisplay(total_charge(sum).format(true));

                        }

                    }, {
                        icon: tableIcons.SelectAll,
                        tooltip: 'Deselect all',
                        onClick: () => {
                            setAllCheck(!allCheck);
                            tableRef.current.state.renderData.map(ren =>
                                ren.tableData.checked = false
                            )
                            setTotal('$0');
                            setTotalDisplay('$0');
                        }

                    },
                ]}

                detailPanel={[
                    {
                        tooltip: 'Shipment Detail',
                        render: rowData => {
                            return (
                                <div
                                    style={{
                                        fontSize: 60,
                                        textAlign: 'left',
                                        paddingLeft: 0,
                                        color: 'white',
                                        backgroundColor: 'none',
                                       
                                    }}
                                >
                                    <Shipment house_number={rowData.house_number} deliver={handleDelivery} opener={() => { handleClickOpen(rowData.account_id, rowData.id, rowData.total_charge) }} total={rowData.total_charge} tracking={rowData.tracking} id={rowData.id} uid={rowData.account_id} fname={rowData.fname.toLowerCase()} lname={rowData.lname.toLowerCase()} address={rowData.address} addinfo={rowData.addinfo} phone={rowData.phone} />
                                </div>
                            )
                        },
                    },
                ]}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showSnack}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                message={"Deliver ALL, must be applied to the same Account"}
            />
        </div>
    );
}

