import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { LockTwoTone } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Redirect } from 'react-router-dom';
import { authenticate } from './auth';
import Cookies from 'js-cookie'

// import { useAuth } from "./context/auth";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.shippcartja.com/">
                ShippCart Jamaica Ltd.
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [show, setShow] = useState(false);
    const [alertMsg, setAlertMessage] = useState("");
    const [loader, showLoader] = useState("hidden");
 
    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    }

    const mountedRef = useRef(true);
    useEffect(() => {
        mountedRef.current = false
    })

    let login = async () => {
        showLoader("visible")
        await authenticate(email, password)
            .then(res => {
                if (res.token) { //logged in
                    var sessionExpire = new Date(new Date().getTime() + (60 * 3) * 60 * 1000); //(mins)/secs/milli-secs
                    Cookies.set('__session', res.token, { expires: sessionExpire });
                    //if (!mountedRef.current) return null;
                    setLoggedIn(true);
                    showLoader("hidden")
                } else {
                    setAlertMessage(res.message);
                    setShow(true);
                    setLoggedIn(false);
                    showLoader("hidden")
                }
            })
            .catch(err => {
                setAlertMessage(err.message);
                setShow(true);
                setLoggedIn(false);
                showLoader("hidden")
            });
    }
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShow(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        login();
    }

    if (isLoggedIn) { //state update
        // return <Redirect to="/home" />;
        window.location = '/';
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />            
            <div className={classes.paper} style={{paddingTop:50}}>
               
                {/* <Avatar className={classes.avatar}> */}
                    {/* <LockTwoTone /> */}
                {/* </Avatar> */}
                <Typography component="h1" variant="h5">
                    Sign in
        </Typography>
       
                <form className={classes.form} onSubmit={handleSubmit}>
                
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}

                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!validateForm()}
                    >
                        Sign In
          </Button>
                    <Grid container>
                        <Grid item xs={12}>
                        <LinearProgress  style={{visibility:loader}} />
                            {/* <Link target="_new" href="#" variant="body2">
                                Forgot password?
                            </Link> */}
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={show}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                message={alertMsg}
            />
        </Container>
    );
}