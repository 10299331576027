import React, { useState, createContext, useEffect } from 'react';
// import { getData } from '../util';
// import {getSessionToken, inSession } from '../session';
import {inSession } from '../session';

export const ShipmentContext = createContext();

export const ShipmentProvider = props => {
 
    useEffect(() => {
        if (inSession()) {
        //    getShipments();
        }
    });


    const [shipments, setShipments] = useState([]);
    
    // const getShipments = async () => {
    //     const data = await getData("https://www.malidaddy.net/api/admin/cbox/shipments-delivery/100/none", {}, {
    //         'Content-Type': 'application/json', Authorization: "Bearer " + getSessionToken()
    //     });
    //     setShipments(data.data);
    // }

    return (
        <ShipmentContext.Provider value={[shipments, setShipments]}>
            {props.children}
        </ShipmentContext.Provider>
    );

}

