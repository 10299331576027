import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BottomNavigationAction, BottomNavigation } from '@material-ui/core';
import { Home, FormatListNumberedRtl, Info, LocalShipping, } from '@material-ui/icons';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { inSession } from './session';
import { getJWTInfo } from "./session";
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


function Nav() {
  const [navValue, setNavValue] = useState("");
  let history = useHistory();
  const BottomNavigationCBX = withStyles({
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
    }
  })(BottomNavigation);
  return (
    <BottomNavigationCBX defaultValue="home" value={navValue} onChange={(event, newValue) => {
      setNavValue(newValue);
      if (inSession() === true) {
        history.push("/" + newValue);
      } else {
        return false;
      }
      // history.push("/"+newValue)
      // console.log("Btn", event);
      // console.log("Btn New value", newValue);
    }}>
      <BottomNavigationAction label="Home" value="home" icon={<Home />} />
      {/* <BottomNavigationAction label="Members" value="verify" icon={<PeopleAlt />} /> */}
      {/* <BottomNavigationAction label="Favorites" value="favorites" icon={<Favorite />} /> */}
      {/* <BottomNavigationAction label="Nearby" value="nearby" icon={<LocationOn/>} /> */}

      <BottomNavigationAction label="Packages" value="list" icon={<FormatListNumberedRtl />} />


      <BottomNavigationAction label="Deliveries" value="deliveries" icon={<LocalShipping />} />
      {getJWTInfo().permission > 1 ?
        <BottomNavigationAction label="Identify" value="identify" icon={<Info />} /> : ""}

      {getJWTInfo().permission > 1 ?
        <BottomNavigationAction label="Ready" value="scan" icon={<CropFreeIcon />} /> : ""}



    </BottomNavigationCBX>
  );
}
export default Nav;