import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Phone, SendSharp} from '@material-ui/icons';


function Shipment(props){
    const useStyles = makeStyles({
        root: {
        //   maxWidth: 345,
        //   marginTop: 10,
        },
        actions:{
            float:'right'
        },
        cap:{
            textTransform: 'capitalize'
        }, order:{
            textTransform: 'capitalize',
            color:'crimson',
            fontWeight:'bold'
        },addInfo:{
            color:'#f50457',
        },
        total:{
         
            fontSize: 21,
            padding: 3,
            paddingRight:9,
            fontWeight: 'bold',
            background: '#8db703',
            color: '#fff'
        }
      });
      const classes = useStyles();

      //prop.pin = this pin
      //prop.order = this order

    return(
   
        <Card className={classes.root}  style={{width:'100vw'}} >
        <CardActionArea>
          <CardContent>
          <Typography className={classes.total} gutterBottom component="">
              {props.total}
            </Typography>
            <Typography className={classes.order} gutterBottom component="p">
              Order#: {props.id}
            </Typography>
            <Typography className={classes.cap} gutterBottom variant="h5" component="h2">
              {props.fname} {props.lname}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            {props.uid}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            Tracking#: {props.tracking}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            Package#: {props.house_number}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            <Link href="#">{props.address}</Link>
            </Typography>
            <Typography className={classes.addInfo} variant="body2" color="textSecondary" component="p">
            {props.addinfo}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions >
          <Button size="small" color="primary" href={"tel:"+props.phone}>
          <Phone/> Call {props.fname.substr(0,10)} 
          </Button>
          <Button size="small" color="primary" onClick={props.opener}>
          <SendSharp/>  Deliver
          </Button>
        </CardActions>
      </Card>
     
    )
}


export default Shipment;
