import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const getSession = () => {
  const jwt = Cookies.get("__session");
  let session;
  try {
    if (jwt) {
      const base64Url = jwt.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64));
    }
  } catch (error) {
    console.log(error);
  }
  return session;
};
export const inSession = () => {
  const cookie_token = Cookies.get("__session");
  if (!cookie_token) {
    return false;
  }
  const jwt = jwt_decode(cookie_token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return true;
  } else {
    Cookies.remove("__session");
    return false;
  }

  // const jwt = Cookies.get("__session");
  // let session;
  // try { 
  //   if (jwt) {
  //     const base64Url = jwt.split(".")[1];
  //     const base64 = base64Url.replace("-", "+").replace("_", "/");
  //     // what is window.atob ?
  //     // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
  //     session = JSON.parse(window.atob(base64));
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
  // if (session) {
  //   if (session.email) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }
};
export const getSessionToken = () => {
  return Cookies.get("__session");
};
export const logOut = () => {
  Cookies.remove("__session");
};

export const getJWTInfo = () => {
  const cookie_token = Cookies.get("__session");
  if (!cookie_token) {
    return false
  }
  const jwt = jwt_decode(cookie_token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return jwt;
  } else {
    Cookies.remove("__session");
    return false;
  }
};
